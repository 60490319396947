@use "setting" as *;

/*
 base
------------------------------------ */
html {
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;

  -ms-text-size-adjust: 100%;
}

body,
input,
textarea,
pre,
code {
  position: relative;
  color: $white;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font_1;
}

html,
body {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
}

a {
  text-decoration: none;
  transition: color .3s;
}

input::placeholder,
textarea::placeholder {
  color: $black_3;
}

img {
  max-width: 100%;
  pointer-events: none;

  user-select: none;
}
