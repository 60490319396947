@use "setting" as *;

.c_ttl_1 {
  position: relative;
  z-index: 2;
  margin: pv3(20) auto pv3(110);
  text-align: center;
  text-shadow: 0 0 pv3(5) rgba(0,42,193,.8);
  font-size: pv3(40);
  line-height: pv3(47.2);
  @media only screen and (max-width: 767px) {
    margin: pv(20) auto pv(60);
    text-shadow: 0 0 pv(5) rgba(0,42,193,.8);
    font-size: pv(30);
    line-height: 1.25;
  }
  span {
    position: absolute;
    top: calc(50% - pv3(10));
    left: 50%;
    color: transparent;
    text-shadow: 0 0 0 rgba(0,42,193,0);
    font-size: pv3(100);
    font-family: $font_2;
    line-height: pv3(40);
    transform: translate(-50%, -50%);

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $white;
    @media only screen and (max-width: 767px) {
      top: calc(50% - pv(5));
      font-size: pv(50);
      line-height: 1;
    }
  }
  &.u_pt100_pc {
    span {
      top: 0;
      transform: translateX(-50%);
    }
  }
}

.c_ttl_2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  color: #00a0e9;
  font-size: pv3(300);
  font-family: $font_2;
  line-height: pv3(300);
  opacity: .1;
  pointer-events: none;
  @media only screen and (max-width: 767px) {
    font-size: pv(70);
    line-height: pv(70);
  }
}

.c_ttl_3 {
  position: relative;
  top: pv3(-10);
  line-height: 1;
}

.c_ttl_4 {
  position: relative;
  z-index: 2;
  margin: pv3(20) auto pv3(110);
  text-align: center;
  font-size: pv3(40);
  line-height: pv3(47.2);
  @media only screen and (max-width: 767px) {
    margin: pv(20) auto pv(60);
    font-size: pv(30);
    line-height: 1.25;
  }
  &._l {
    text-align: left;
  }
}

.c_ttl_5 {
  position: relative;
  z-index: 2;
  margin: 0 auto pv3(25);
  text-align: center;
  font-size: pv3(90);
  line-height: pv3(156);
  @media only screen and (max-width: 767px) {
    margin: pv(30) auto pv(20);
    font-size: pv(26);
    line-height: 1.25;
  }
  span {
    position: relative;
    display: block;
    color: $white;
    font-size: pv3(51.25);
    font-family: $font_2;
    line-height: pv3(52);
    @media only screen and (max-width: 767px) {
      font-size: pv(20);
      line-height: 1.25;
    }
  }
}

.c_ttl_6 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: 0 auto pv3(18);
  padding: pv3(30) !important;
  max-width: pv3(1120);
  border-radius: pv3(20);
  background-color: $white;
  color: $color_1;
  font-size: pv3(40);
  line-height: 1.25;
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    margin: pv(20) auto pv(20);
    padding: pv(15) pv(20) pv(25) !important;
    max-width: 100%;
    border-radius: pv(10);
    font-size: pv(22);
    line-height: 1.25;
  }
  .en {
    position: absolute;
    top: 50%;
    right: pv3(30);
    z-index: -1;
    display: block;
    color: $white;
    letter-spacing: pv3(-1);
    font-size: pv3(48);
    font-family: $font_2;
    line-height: pv3(75);
    transform: translateY(-50%);

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color_1;
    @media only screen and (max-width: 767px) {
      position: relative;
      top: 0;
      letter-spacing: 0;
      font-size: pv(22);
      line-height: 1.5;
      transform: translateY(0);
    }
  }
}

.c_ttl_7 {
  margin: pv3(50) auto pv3(18);
  padding: pv3(10);
  border-radius: pv3(10);
  background-color: #ffe100;
  font-weight: bold;
  font-size: pv3(26);
  line-height: pv3(37.5);
  @media only screen and (max-width: 767px) {
    margin: pv(40) auto pv(20);
    padding: pv(10);
    border-radius: pv(10);
    font-size: pv(20);
    line-height: 1.25;
  }
}

.c_ttl_8 {
  position: relative;
  margin: 0 auto pv3(50);
  color: transparent;
  text-align: center;
  font-size: pv3(100);
  font-family: $font_2;
  line-height: pv3(110);

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $white;
  @media only screen and (max-width: 767px) {
    top: calc(50% - pv(5));
    margin: 0 auto pv(30);
    font-size: pv(50);
    line-height: 1;
  }
}

.c_ttl_9 {
  position: relative;
  z-index: 2;
  margin: pv3(20) auto pv3(70);
  text-align: center;
  font-size: pv3(32);
  line-height: pv3(47.2);
  @media only screen and (max-width: 767px) {
    margin: pv(20) auto pv(40);
    font-size: pv(18);
    line-height: 1.25;
  }
  &._2 {
    margin: pv3(20) auto pv3(9);
    @media only screen and (max-width: 767px) {
      margin: pv(5) auto pv(8);
    }
  }
  &._l {
    text-align: left;
  }
}

.c_ttl_10 {
  position: relative;
  z-index: 2;
  margin: 0 auto pv3(25);
  text-align: center;
  font-size: pv3(100);
  line-height: pv3(156);
  @media only screen and (max-width: 767px) {
    margin: pv(30) auto pv(20);
    font-size: pv(29);
    line-height: 1.25;
  }
  span {
    position: relative;
    display: block;
    color: $white;
    font-size: pv3(51.25);
    font-family: $font_2;
    line-height: pv3(52);
    @media only screen and (max-width: 767px) {
      font-size: pv(20);
      line-height: 1.25;
    }
  }
}

.c_yt_box {
  aspect-ratio: 16 / 9;
  max-width: pv3(1200);
  width: 100%;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

.c_btn_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: pv3(583);
  height: pv3(72.4);
  border: 1px solid $color_1;
  border-radius: pv3(10);
  background-color: transparent;
  color: $color_1;
  transition: background-color .3s, color .3s;
  @media only screen and (max-width: 767px) {
    padding: pv(15);
    width: pv(335);
    height: auto;
    border-radius: pv(10);
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: $color_1;
      color: $white;
    }
  }
}
.c_btn_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: pv3(348);
  height: pv3(100);
  border: 1px solid $color_1;
  border-radius: pv3(10);
  background-color: $color_1;
  color: $white;
  transition: background-color .3s, color .3s;
  @media only screen and (max-width: 767px) {
    padding: pv(15);
    width: pv(335);
    height: auto;
    border-radius: pv(10);
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: $white;
      color: $color_1;
    }
  }
}
.c_btn_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: pv3(360);
  height: pv3(110);
  border-radius: pv3(10);
  background-color: rgba(0,220,255,.7);
  color: $white;
  transition: background-color .3s, color .3s;
  @media only screen and (max-width: 767px) {
    padding: pv(15);
    width: pv(335);
    height: auto;
    border-radius: pv(10);
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: $white;
      color: $color_1;
    }
  }
}
.c_btn_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  //width: pv3(640);
  width: pv3(800);
  height: pv3(165);
  border-radius: pv3(10);
  background-color: #ffe100;
  color: #231815;
  transition: background-color .3s;
  @media only screen and (max-width: 767px) {
    padding: pv(15);
    width: pv(335);
    height: auto;
    border-radius: pv(10);
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      background-color: $white;
    }
  }
}

.c_blog_list {
  display: flex;
  gap: pv3(57);
  margin: 0 auto;
  max-width: pv3(1300);
  //background-color: $white;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: pv(20);
    max-width: 100%;
  }
  .item {
    width: calc(33.33% - pv3(114) / 3);
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    .link {
      display: block;
      opacity: 1;
      transition: opacity .3s;
      .img_wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: pv3(287);
        @media only screen and (max-width: 767px) {
          height: pv(200);
        }
        .thumb,
        .wp-post-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform .3s;
          transform: scale(1);
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: rgb(181, 181, 181);
          color: $white;
          font-weight: bold;
          font-size: pv3(20);
          @media only screen and (max-width: 767px) {
            font-size: pv(18);
          }
        }
      }
      .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: pv3(14) 0 pv3(9);
        @media only screen and (max-width: 767px) {
          margin: pv(10) 0;
        }
        .cate_list {
          display: flex;
          gap: pv3(10);
          @media only screen and (max-width: 767px) {
            gap: pv(5);
          }
        }
        .category {
          padding: 0 pv3(23);
          width: fit-content;
          border-radius: pv3(5);
          background-color: #65ac9e;
          color: $white;
          line-height: pv3(30);
          @media only screen and (max-width: 767px) {
            padding: 0 pv(10);
            border-radius: pv(5);
            line-height: pv(20);
          }
        }
        .date {
          color: #2f3131;
        }
      }
      .ttl {
        color: #2f3131;
      }

      &:hover {
        @media only screen and (min-width: 768px) {
          opacity: .8;
          .img_wrap {
            .thumb,
            .wp-post-image {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

.c_news_list {
  display: flex;
  flex-wrap: wrap;
  gap: pv3(95);
  margin: 0 auto;
  max-width: pv3(1405);
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: pv(20);
    max-width: 100%;
  }
  .item {
    width: calc(33.33% - pv3(190) / 3);
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    .link {
      display: block;
      opacity: 1;
      transition: opacity .3s;
      .img_wrap {
        position: relative;
        overflow: hidden;
        margin-bottom: pv3(19);
        //height: pv3(270);
        //aspect-ratio: 1.5 / 1;
        aspect-ratio: 1 / 1;
        width: 100%;
        @media only screen and (max-width: 767px) {
          margin-bottom: pv(10);
          //height: pv(250);
        }
        .thumb,
        .wp-post-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform .3s;
          transform: scale(1);
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: rgb(181, 181, 181);
          color: $white;
          font-weight: bold;
          font-size: pv3(20);
          @media only screen and (max-width: 767px) {
            font-size: pv(18);
          }
        }
      }
      .cate_list {
        display: flex;
        gap: pv3(15);
        @media only screen and (max-width: 767px) {
          gap: pv(5);
        }
      }
      .category {
        display: block;
        padding: 0 pv3(23);
        width: fit-content;
        background-color: #f00;
        color: $white;
        font-size: pv3(12);
        line-height: pv3(24);
        @media only screen and (max-width: 767px) {
          padding: 0 pv(10);
          font-size: pv(12);
          line-height: pv(20);
        }
      }
      .date {
        color: $white;
      }
      .ttl {
        margin: pv3(11) 0 pv3(16);
        color: $white;
        @media only screen and (max-width: 767px) {
          margin: pv(10) 0;
        }
      }

      &:hover {
        @media only screen and (min-width: 768px) {
          opacity: .8;
          .img_wrap {
            .thumb,
            .wp-post-image {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

.c_news_list_2 {
  margin: pv3(150) auto pv3(50);
  max-width: pv3(920);
  border-bottom: 1px solid #fff;
  @media only screen and (max-width: 767px) {
    margin: pv(40) auto pv(60);
    max-width: 100%;
  }
  .item {
    border-top: 1px solid #fff;
    .link {
      display: block;
      padding: pv3(13) pv3(20);
      width: 100%;
      color: #fff;
      font-weight: bold;
      font-size: pv3(16);
      opacity: 1;
      transition: opacity .3s;
      @media only screen and (max-width: 767px) {
        padding: pv(20) pv(15);
        font-size: pv(16);
      }
      .date {
        display: inline-block;
        width: pv3(150);
        color: $white;
        @media only screen and (max-width: 767px) {
          display: block;
          width: 100%;
        }
      }
      &:hover {
        @media only screen and (min-width: 768px) {
          opacity: .7;
        }
      }
    }
  }
}

.c_table_1 {
  margin: pv3(12) auto 0;
  max-width: 800px;
  width: 100%;
  border: 1px solid #fff;
  th {
    padding: pv3(18);
    border: 1px solid #fff;
    text-align: left;
    font-weight: bold;
    font-size: pv3(26);
    @media only screen and (max-width: 767px) {
      padding: pv(15);
      font-size: pv(18);
    }
  }
  td {
    padding: pv3(20);
    border: 1px solid #fff;
    font-weight: bold;
    font-size: pv3(26);
    @media only screen and (max-width: 767px) {
      padding: pv(15);
      font-size: pv(16);
    }
  }
}

.c_performance_list {
  margin: 0 auto;
  max-width: pv3(1120);
  color: #fff;
  font-weight: bold;
  font-size: pv3(24);
  line-height: 2;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    font-size: pv(16);
  }
  .item {
    display: flex;
    .date {
      width: pv3(90);
      @media only screen and (max-width: 767px) {
        width: pv(60);
      }
    }
    .txt {
      width: calc(100% - pv3(90));
      @media only screen and (max-width: 767px) {
        width: calc(100% - pv(60));
      }
    }
  }
}
.c_performance_list_2 {
  margin: 0 auto;
  max-width: pv3(1120);
  font-weight: bold;
  font-size: pv3(24);
  line-height: 2;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    font-size: pv(14);
  }
  .item {
    display: flex;
    .date {
      width: pv3(220);
      @media only screen and (max-width: 767px) {
        width: pv(110);
      }
      .month {
        padding-left: pv3(70);
        @media only screen and (max-width: 767px) {
          padding-left: pv(40);
        }
      }
    }
    .txt {
      width: calc(100% - pv3(220));
      @media only screen and (max-width: 767px) {
        width: calc(100% - pv(110));
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
