@use "setting" as *;
:root {
  --vw: 1vw;
}

body {
  overflow-x: hidden !important;
  width: calc(var(--vw) * 100);
}
body.modal_open {
  overflow: hidden;
}
.js-fade_up {
  position: relative;
  top: pv3(40);
  opacity: 0;
  transition: opacity .3s, top .4s;
  @media only screen and (max-width: 767px) {
    top: pv(30);
  }
  &._active {
    top: 0;
    opacity: 1;
  }
}

.l_header {
  position: relative;
  z-index: 99;
  width: 100%;
  @media only screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(var(--vw) * 100);
  }
  .header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: pv3(200);
    @media only screen and (max-width: 767px) {
      height: pv(60);
      background-color: $white;
    }
  }
  .logo_wrap {
    position: relative;
    top: pv3(76);
    left: pv3(36);
    width: pv3(328);
    @media only screen and (max-width: 1767px) {
      top: pv3(30);
      left: pv3(15);
      width: pv3(270);
    }
    @media only screen and (max-width: 767px) {
      top: pv(0);
      left: pv(5);
      width: pv(60);
    }
    .logo_link {
      display: block;
      .logo {
        width: 100%;
        opacity: 1;
        transition: opacity .3s;
      }
      &:hover {
        .logo {
          opacity: .8;
        }
      }
    }
  }
  .nav_btn {
    z-index: 99;
    display: flex;
    align-items: center;
  }
  .humb {
    position: relative;
    right: pv(15);
    width: pv(40);
    height: pv(40);
    span {
      position: absolute;
      top: pv(5);
      width: pv(40);
      height: 2px;
      background: #2096d5;
      opacity: 1;
      transition: opacity .3s, transform .3s, top .3s;
      transform: rotate(0);
      &:nth-of-type(2) {
        top: pv(18);
        opacity: 1;
      }
      &:nth-of-type(3) {
        top: pv(31);
        opacity: 1;
        transform: rotate(0);
      }
    }
  }
  .l_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    @media only screen and (max-width: 767px) {
      position: absolute;
      top: pv(60);
      left: 100vw;
      overflow-y: auto;
      flex-direction: column;
      width: 100%;
      height: calc(100dvh - pv(60));
      background-color: $white;
      transition: left .3s;
    }
    .list {
      display: flex;
      align-items: center;
      margin: pv3(0) pv3(10) 0 pv3(20);
      //max-width: pv3(1300);
      @media only screen and (max-width: 1767px) {
        //flex-wrap: wrap;
        justify-content: flex-end;
      }
      @media only screen and (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
        margin: pv(20) 0;
        max-width: 100%;
        width: 100%;
      }
      .item {
        position: relative;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        & + .item {
          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: pv3(1);
            height: pv3(40);
            border-left: 1px solid #2096d5;
            color: rgba(32, 150, 213, 1);
            content: "";
            font-weight: bold;
            transform: translateY(-50%);
            @media only screen and (max-width: 767px) {
              top: 0;
              left: pv(20);
              width: calc(100% - pv(40));
              border-top: 1px solid #2096d5;
              border-left: none;
              transform: translateY(0);
            }
          }
        }
        .link {
          display: block;
          padding: pv3(10) pv3(25);
          color: rgba(32, 150, 213, 1);
          white-space: nowrap;
          word-break: keep-all;
          font-weight: bold;
          font-size: pv3(18);
          line-height: 1.25;
          opacity: 1;
          transition: opacity .3s;
          @media only screen and (min-width: 768px) {
            text-align: center;
          }
          @media only screen and (max-width: 767px) {
            padding: pv(15) pv(20);
            width: 100%;
            font-size: pv(18);
          }
          &:hover {
            opacity: .75;
          }
        }
      }
    }
    .sns_list {
      display: flex;
      .link {
        display: block;
        width: pv3(50);
        @media only screen and (max-width: 767px) {
          width: pv(50);
        }
        .ico {
          height: pv3(27.85);
          @media only screen and (max-width: 767px) {
            height: pv(30);
          }
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: pv3(22);
      width: pv3(198);
      height: pv3(70);
      border-radius: pv3(10);
      background-color: #00a0e9;
      color: #fff;
      font-weight: bold;
      font-size: pv3(20);
      opacity: 1;
      transition: opacity .3s;
      @media only screen and (max-width: 767px) {
        margin: pv(20) auto;
        width: calc(100% - pv(40));
        height: pv(50);
        border-radius: pv(10);
        font-size: pv(20);
      }
      &:hover {
        opacity: .8;
      }
    }
    .cta {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: pv3(0) pv3(10);
      width: pv3(300);
      height: pv3(200);
      background: #f4d923;
      color: rgba(32, 150, 213, 1);
      white-space: nowrap;
      word-break: keep-all;
      @media only screen and (max-width: 767px) {
        width: 100%;
        height: pv(130);
      }
      .deco {
        margin-bottom: pv3(11);
        width: pv3(56);
        @media only screen and (max-width: 767px) {
          margin-bottom: pv(8);
          width: pv(40);
        }
      }
      .txt_1 {
        font-weight: bold;
        font-size: pv3(18);
        line-height: pv3(23);
        @media only screen and (max-width: 767px) {
          font-size: pv(16);
          line-height: 1.25;
        }
      }
      .tel {
        color: rgba(32, 150, 213, 1);
        font-size: pv3(50);
        font-family: $font_2;
        line-height: pv3(65);
        @media only screen and (min-width: 768px) {
          text-decoration: none;
          cursor: default;
          pointer-events: none;
        }
        @media only screen and (max-width: 767px) {
          font-size: pv(35);
          line-height: 1.25;
        }
      }
      .txt_2 {
        font-weight: bold;
        font-size: pv3(14);
        line-height: pv3(25);
        @media only screen and (max-width: 767px) {
          font-size: pv(14);
          line-height: 1.5;
        }
      }
    }
  }
  .sns_list_2 {
    justify-content: flex-end;
    gap: pv(15);
    width: calc(100% - pv(200));
    @media only screen and (min-width: 768px) {
      display: none;
    }
    .link {
      display: block;
      @media only screen and (max-width: 767px) {
        height: pv(20);
      }
      .ico {
        height: pv3(27.85);
        @media only screen and (max-width: 767px) {
          height: pv(20);
        }
      }
    }
  }
  &.sp_open {
    .humb {
      span {
        top: pv(18);
        transform: rotate(45deg);
        &:nth-of-type(2) {
          top: pv(18);
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
      }
    }
    .l_nav {
      left: 0;
    }
  }
}

.l_kv {
  position: relative;
  @media only screen and (max-width: 767px) {
  }
  .kv {
    width: 100%;
    @media only screen and (max-width: 767px) {
      position: relative;
      left: pv(-1);
      z-index: 0;
      max-width: calc(100% + pv(1));
      width: calc(100% + pv(2));
    }
  }
}

.l_contain {
  width: calc(var(--vw) * 100);
}

.l_contents {
  width: calc(var(--vw) * 100);
  @media only screen and (max-width: 767px) {
    position: relative;
    overflow-x: hidden;
    margin-top: pv(60);
  }
}

.l_footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: pv3(140);
  margin: pv3(350) 0 0;
  padding: pv3(115) 0;
  border-top: 1px solid #fff;
  color: $white;
  font-size: pv3(16);
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: pv(50);
    margin: pv(120) 0 0;
    padding: pv(100) pv(20);
    font-size: pv(16);
  }
  .ttl {
    margin-bottom: pv3(34);
    padding-bottom: pv3(34);
    width: 100%;
    border-bottom: 1px solid #fff;
    text-align: left;
    font-weight: normal;
    font-size: pv3(16);
    @media only screen and (max-width: 767px) {
      margin-bottom: pv(34);
      padding-bottom: pv(34);
      font-size: pv(16);
    }
  }
  .left {
    display: flex;
    flex-wrap: wrap;
    gap: 0 pv3(50);
    width: pv3(510);
    @media only screen and (max-width: 767px) {
      gap: 0 pv(20);
      width: 100%;
    }
  }
  .right {
    width: pv3(450);
    font-weight: bold;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    .item {
      .link {
        display: inline-block;
        padding: pv3(11) 0;
        color: #fff;
        font-weight: bold;
        @media only screen and (max-width: 767px) {
          padding: pv(10) 0;
        }
      }
      &.last {
        margin-top: auto;
      }
    }
  }
  .copy {
    width: 100%;
    text-align: center;
  }
}

.sp_block {
  position: absolute;
  pointer-events: none;
}

.float {
  position: fixed;
  right: pv3(82);
  bottom: 0;
  z-index: 98;
  width: pv3(304);
  opacity: 1;
  transition: opacity .3s;
  @media only screen and (max-width: 767px) {
    right: pv(17);
    width: pv(100);
  }
  &:hover {
    @media only screen and (min-width: 768px) {
      opacity: .8;
    }
  }
}
