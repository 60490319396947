html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
blockquote,
pre,
address,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
header,
footer {
  box-sizing: border-box;
  margin: 0; padding: 0;
}
table {
  width: 100%; border-spacing: 0;
  border-collapse: collapse;
}
ul {
  list-style: none;
}
img {
  max-width: 100%; height: auto; border: 0;
}
img,
input {
  vertical-align: middle;
}
.object-fit-img {
  object-fit: cover; font-family: "object-fit: cover;";
}
