@use "setting" as *;

.u_pc {
  display: block !important;
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}
.u_pc_i {
  display: inline;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.u_pc_f {
  display: flex !important;
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}
.u_sp {
  display: none !important;
  @media only screen and (max-width: 767px) {
    display: block !important;
  }
}
.u_sp_f {
  display: none !important;
  @media only screen and (max-width: 767px) {
    display: flex !important;
  }
}
.u_sp_i {
  display: none;
  @media only screen and (max-width: 767px) {
    display: inline;
  }
}

/* text size */
.u_txt_1 {
  font-size: pv3(70);
  line-height: pv3(75);
  @media only screen and (max-width: 767px) {
    font-size: pv(23);
    line-height: pv(30);
  }
}
.u_txt_2 {
  letter-spacing: 0;
  font-size: pv3(30);
  line-height: pv3(52.4);
  @media only screen and (max-width: 767px) {
    font-size: pv(16);
    line-height: pv(24);
  }
}
.u_txt_3 {
  font-size: pv3(40);
  line-height: pv3(45);
  @media only screen and (max-width: 767px) {
    font-size: pv(28);
    line-height: pv(35);
  }
}
.u_txt_4 {
  font-size: pv3(16);
  line-height: pv3(28);
  @media only screen and (max-width: 767px) {
    font-size: pv(14);
    line-height: pv(19);
  }
}
.u_txt_5 {
  font-size: pv3(36);
  line-height: pv3(41);
  @media only screen and (max-width: 767px) {
    font-size: pv(18);
    line-height: pv(26);
  }
}
.u_txt_6 {
  font-size: pv3(24);
  line-height: pv3(37.5);
  @media only screen and (max-width: 767px) {
    font-size: pv(14);
    line-height: pv(20);
  }
}
.u_txt_7 {
  font-size: pv3(16);
  line-height: pv3(22.5);
  @media only screen and (max-width: 767px) {
    font-size: pv(14);
    line-height: pv(19);
  }
}
.u_txt_8 {
  font-size: pv3(28);
  line-height: pv3(32.5);
  @media only screen and (max-width: 767px) {
    font-size: pv(18);
    line-height: pv(26);
  }
}
.u_txt_9 {
  font-size: pv3(18);
  line-height: pv3(24);
  @media only screen and (max-width: 767px) {
    font-size: pv(16);
    line-height: pv(20);
  }
}
.u_txt_10 {
  font-size: pv3(40);
  line-height: pv3(65);
  @media only screen and (max-width: 767px) {
    font-size: pv(20);
    line-height: pv(34);
  }
}
.u_txt_11 {
  font-size: pv3(20);
  line-height: pv3(35);
  @media only screen and (max-width: 767px) {
    font-size: pv(16);
    line-height: pv(20);
  }
}
.u_txt_12 {
  font-size: pv3(26);
  line-height: pv3(30);
  @media only screen and (max-width: 767px) {
    font-size: pv(18);
    line-height: pv(26);
  }
}
.u_txt_13 {
  font-size: pv3(14.4);
  line-height: pv3(18);
  @media only screen and (max-width: 767px) {
    font-size: pv(13);
    line-height: pv(16);
  }
}
.u_txt_14 {
  font-size: pv3(51.25);
  line-height: pv3(53);
  @media only screen and (max-width: 767px) {
    font-size: pv(30);
    line-height: pv(35);
  }
}
.u_txt_15 {
  font-size: pv3(24);
  line-height: pv3(42);
  @media only screen and (max-width: 767px) {
    font-size: pv(16);
    line-height: pv(28);
  }
}
.u_txt_16 {
  font-size: pv3(32);
  line-height: pv3(42);
  @media only screen and (max-width: 767px) {
    font-size: pv(18);
    line-height: pv(28);
  }
}
.u_txt_17 {
  font-size: pv3(37);
  line-height: pv3(42);
  @media only screen and (max-width: 767px) {
    font-size: pv(18);
    line-height: pv(28);
  }
}
.u_txt_18 {
  font-size: pv3(45);
  line-height: pv3(56);
  @media only screen and (max-width: 767px) {
    font-size: pv(24);
    line-height: pv(32);
  }
}
.u_txt_19 {
  font-size: pv3(21);
  line-height: pv3(42);
  @media only screen and (max-width: 767px) {
    font-size: pv(16);
    line-height: pv(28);
  }
}

/* text weight */
.u_normal {
  font-weight: normal;
}
.u_medium {
  font-weight: 500;
}
.u_semibold {
  font-weight: 600;
}
.u_bold {
  font-weight: 700;
}
.u_exbold {
  font-weight: 900;
}

.u_uline {
  border-bottom: 1px solid #000;
}

/* text align */
.u_txt_c {
  text-align: center;
}
.u_txt_l {
  text-align: left;
}
.u_txt_lc {
  text-align: center;
  @media only screen and (max-width: 767px) {
    text-align: left;
  }
}
.u_txt_r {
  text-align: right;
}

.no_wrap {
  white-space: nowrap;
}

/* text color */
.u_color_1 {
  color: $color_1;
}
.u_color_2 {
  color: $color_2;
}
.u_color_3 {
  color: $color_4;
}
.u_color_4 {
  color: $white;
}
.u_color_5 {
  color: #534741;
}
.u_color_6 {
  color: #ccc;
}
.u_color_7 {
  color: #333;
}
.u_color_8 {
  color: #f00;
}


/* font */
.u_ff_1 {
  font-family: $font_2;
}

/* bg */
.u_bg_1 {
  background-color: $color_2;
}
.u_bg_2 {
  background-color: $color_4;
}

.u_paragraph {
  & + .u_paragraph {
    margin-top: 19px;
    @media only screen and (max-width: 767px) {
      margin-top: pv(29);
    }
  }
}

.u_mb40_pc {
  @media only screen and (min-width: 768px) {
    margin-bottom: pv3(40);
  }
}
.u_mb55_pc {
  @media only screen and (min-width: 768px) {
    margin-bottom: pv3(55);
  }
}
.u_mb60_pc {
  @media only screen and (min-width: 768px) {
    margin-bottom: pv3(60);
  }
}
.u_mb30_sp {
  @media only screen and (max-width: 767px) {
    margin-bottom: pv(30);
  }
}
.u_mb40_sp {
  @media only screen and (max-width: 767px) {
    margin-bottom: pv(40);
  }
}

.u_pt100_pc {
  @media only screen and (min-width: 768px) {
    padding-top: pv3(100);
  }
}
.u_pt80_sp {
  @media only screen and (max-width: 767px) {
    padding-top: pv(80);
  }
}
.u_pt120_sp {
  @media only screen and (max-width: 767px) {
    padding-top: pv(120);
  }
}

.u_pt80 {
  @media only screen and (min-width: 768px) {
    padding-top: pv3(80);
  }
  @media only screen and (max-width: 767px) {
    padding-top: pv(50);
  }
}
